
<template>
  <div class="BaseTable2">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">人员管理</el-menu-item>
        <el-menu-item index="2">部门管理</el-menu-item>
        <el-menu-item index="3">角色管理</el-menu-item>
        <el-menu-item index="4">字段管理</el-menu-item>
      </el-menu>
      <div class="Men_1">
        销售线索：① 管理系统目前所有的销售线索，可以进行编辑、删除、员工认领；② 可以通过本频道手动添加或批量上传销售线索，用于员工联系与跟踪
      </div>
    </div>

    <!------SSSSSSSSSSSSSSSSSSSSS----------部门管理---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS------------->
    <div class="sadsds">
      <div class="Tables">
        <div class="Tab_T ddsddsx" @click="Add_kq(4)"><i class="el-icon-document" style="font-size: 14px;"></i> 添加子部门</div>
        <div class="Tab_T" @click="Add_kq(5)" v-if="TextMoMo('company:department:add')"><i class="el-icon-plus"></i>新建部门</div>
        <div class="dsds">
          <div class="el_seso" @click="D_sech"><i class="el-icon-search"></i></div>
          <el-input style="width:15%;float:right" v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="D_sech()"></el-input>
        </div>
        <el-table
          :data="BMent"
          row-key="id"
          class="table ddddd"
          style="width: 100%"
          ref="multipleTable"
          @row-click="handleRowClick"
          header-cell-class-name="table-header"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          @selection-change="select_change">
          <el-table-column
            type="selection"
            width="55"
            align="center"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="60"
            align="center"></el-table-column>
          <el-table-column align="center" prop="name" label="部门"></el-table-column>
          <el-table-column align="center" prop="leader.realname" label="部门负责人"></el-table-column>
          <el-table-column align="center" prop="phone" label="联系方式"></el-table-column>
          <el-table-column align="center" prop="staff_nums" label="部门人数"></el-table-column>
          <el-table-column align="center" label="操作" width="350">
            <template #default="scope" >
                <div class="el_butto" style="margin-right:15px">
                  <el-button type="text" @click="Bu_bianj(2, scope.row)" v-if="TextMoMo('company:department:read')">查看</el-button>
                  <el-button type="text" v-if="!TextMoMo('company:department:read')">查看</el-button>
                </div>
                <div class="el_butto">
                  <el-button type="text" @click="Bu_bianj(1, scope.row)" v-if="TextMoMo('company:department:edit')">编辑</el-button>
                </div>
                <div class="el_butto rere">
                    <el-button
                    type="text" v-if="TextMoMo('company:department:del')"
                    class="red"
                    @click="Bu_del(scope.$index, scope.row)"
                    >删除</el-button>
                </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total1">
          </el-pagination>
        </div>
      </div>        
    </div>
    <!-------EEEEEEEEEEEEEEEEEEEE----------部门管理--------------EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE------------->


    <!-- 新建部门 -->
    <el-dialog title="添加/编辑部门" v-model="editVisible" width="30%">
      <el-form ref="form" :model="fForm" label-width="100px">
        <el-form-item label="部门名称：">
          <el-input v-model="fForm.name" placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input v-model="fForm.phone" placeholder="请输入手机号"></el-input>
        </el-form-item> 
        <el-form-item label="上级部门："  v-if="Bmmm == false">
           <el-input v-model="Bmen_Sj1" placeholder="请选择部门" class="El_ii" readonly @focus="Bmen1(1)"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人：">
           <el-input v-model="Bmen_Sj2" placeholder="请选择部门负责人" class="El_ii" readonly @focus="Bmen1(2)"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="quxiao" style="margin-right:15px">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新建部门 -->

    <!-- 新建部门 -->
    <el-dialog title="查看部门" v-model="editVisible11" width="30%">
      <el-form ref="form" :model="fForm" label-width="100px">
        <el-form-item label="部门名称：" >
          <el-input v-model="fForm.name" placeholder="请输入部门名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input v-model="fForm.phone" placeholder="请输入手机号" disabled></el-input>
        </el-form-item> 
        <el-form-item label="上级部门："  v-if="Bmmm == false">
           <el-input v-model="Bmen_Sj1" placeholder="请选择部门" class="El_ii" readonly @focus="Bmen1(1)" disabled></el-input>
        </el-form-item>
        <el-form-item label="部门负责人：">
           <el-input v-model="Bmen_Sj2" placeholder="请选择部门负责人" class="El_ii" readonly @focus="Bmen1(2)" disabled></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible11 = false" style="margin-right:15px">取 消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 新建部门 -->

    <!--确认删除-->
    <el-dialog title="确认删除" v-model="dia_DEl" width="31%">
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dia_DEl = false">取 消</el-button>
          <el-button type="primary" @click="Del_Sure">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--确认删除-->

    <!--修改人员类型-->
    <el-dialog title="修改人员类型" v-model="dialogVisible1" width="35%"> 
      <div class="Tabbl">
        <el-table
        :data="Categ"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column label="选项类别" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.content" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope"> 
            <div v-if="scope.row.type == 'default'">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="96">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                 
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="handleDelete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="Y_ids" @click="Add_leibie"><i class="el-icon-plus"></i>新增选项</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN" style="margin-left:15px">确 定</el-button>
        </div>
      </div>  

    </el-dialog>
    <!--修改人员类型-->

    <!--选择成员-->
    <el-dialog title="选择成员" v-model="dialogVisible5" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <!-- <div  >

            </div> -->
            <div>
              <el-checkbox-group v-model="checkList" @change="bindCheckBox">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx != null">已选：1个员工</div>
          <div v-if="Chan_Tonx == null">已选：0个员工</div>
          <div class="tyhh" v-if="Chan_Tonx != null">
            <img :src="Chan_Tonx.full_avatar" alt="">
            <span>{{Chan_Tonx.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="Close_3" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择成员-->

    <!--选择部门-->
    <el-dialog title="选择部门" v-model="dialogVisible7" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="code"
              ref="tree"
              check-strictly
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="handleClick2"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>

            </el-tree>

            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Tonx2 != null">已选：1个部门</div>
          <div v-if="Bumen_Tonx2 == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Tonx2 != null">
            <!-- <img :src="Bumen_Tonx.full_avatar" alt=""> -->
            <span>{{Bumen_Tonx2.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="BTN_Bumen3" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择部门-->

    <!--选择直属上级成员-->
    <el-dialog title="选择上级" v-model="dialogVisible8" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <!-- <div  >

            </div> -->
            <div>
              <el-checkbox-group v-model="checkList" @change="bindCheckBox">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx2 != null">已选：1个员工</div>
          <div v-if="Chan_Tonx2 == null">已选：0个员工</div>
          <div class="tyhh" v-if="Chan_Tonx2 != null">
            <img :src="Chan_Tonx2.full_avatar" alt="">
            <span>{{Chan_Tonx2.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="Close_4" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择直属上级成员-->

    <!--部门编辑修改名称-->
    <el-dialog title="修改部门名称" v-model="dialogVisible9" width="24.5%">
      <div>
        <el-input v-model="diasible9" placeholder=""></el-input>
      </div>
      <div class="Dityo">
        <div class="dialog-footer" style="padding-right:28px">
          <el-button @click="dialogVisible9 = false">取 消</el-button>
          <el-button type="primary" @click="Btn_xiugai" style="margin-left:15px">保存</el-button>
        </div>
      </div>  
    </el-dialog>
    <!--部门编辑修改名称-->  

    <!--选择上级部门-->
    <el-dialog title="选择上级部门" v-model="dialogVisible10" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left;width:87%">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <!-- <div>
                豆腐网
              </div> -->
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="code"
              ref="tree"
              check-strictly
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="Bmen1_Chan"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>

            </el-tree>

            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Xinj1 != null">已选：1个部门</div>
          <div v-if="Bumen_Xinj1 == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Xinj1 != null">
            <!-- <img :src="Bumen_Tonx.full_avatar" alt=""> -->
            <span>{{Bumen_Xinj1.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="BTN_Bumen2" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择上级部门-->

    <!--选择部门负责人-->
    <el-dialog title="选择部门负责人" v-model="dialogVisible11" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left;width: 87%;">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <div>
              <el-checkbox-group v-model="checkList2" @change="bindCheckBox2">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Xinj2 != null">已选：1个员工</div>
          <div v-if="Bumen_Xinj2 == null">已选：0个员工</div>
          <div class="tyhh" v-if="Bumen_Xinj2 != null">
            <img :src="Bumen_Xinj2.full_avatar" alt="">
            <span>{{Bumen_Xinj2.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>
      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <!-- <el-button @click="Close_2">取 消</el-button> -->
            <el-button type="primary" @click="Close_5" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择部门负责人-->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";  

export default {
  name: "basetable",
  data() {
    return {
      editVisible11:false,
      input_seach:'',
      Bmen_Sj1:'',
      Bmen_Sj2:'',
      Bmmm:false,
      Bumen_Xinj1:null,
      Bumen_Xinj2:null,
      fForm:{
        name:'',
        phone:'',
        staff_nums:'',
      }, 
      ziduan_id:0,
      Ad2_ziduan:'',
      FAFA_l:false,
      FAFA_2:false,
      RRealname:'',
      checkList:[],
      checkList2:[],
      ResginS:[],
      DData:[],
      ZMent:[],
      DMent:[],
      BMent:[],
      DMent2:[],
      tag_obj:[],
      Categ:[],
      Fond_tag:null,
      MMD:[],
      D_input:'', // 搜索部门
      C_input:'', // 搜索成员
      activeIndex: "2",
      more:'../../assets/img/more.png',
      show1:false,
      show3:false,
      input: "",
      num:'',

      Index1:true,   // 首页
      Index2:false,   // 部门管理
      Index4:false,
      Index5:false,    //离职员工
      
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },


      multipleSelection_id: null,
      delList: [],

      dia_DEl:false,
      Remov_id:0,

      editVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible7: false,
      dialogVisible8: false,
      dialogVisible9: false,
      dialogVisible10: false,
      dialogVisible11: false,

      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      total:0,
      total1:0,
      total2:0,
      total3:0,
      value3:0,
      currentPage1:0,
      currentPage3:0,
      currentPage4:0,

      Dy:true,
      tot:0,  //分页
      editStaff_id:0,  //编辑是员工id
      options2:[{name:'待交接',id: 10},{name:'已交接',id: 20},{name:'不需交接',id: 30}],
      sex_arr:[{id:1,name:'男'},{id:2,name:'女'}],
      options1:[{id:10,name:'主动离职'}, {id:20,name:'被动离职'}],
      XX:'',
      diasible9:'',
      diasible9_id:0,

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      
      department_id:0, //部门id

      aTY: 1,        //图片显示判断
          //图片地址
      full_image:'', //图片页面显示地址

      Row:{
        realname:'',     //姓名
        Eurl:'',         //头像
        mobile:'',       //手机号
        job:'',          //职务
        job_number:'',   //工号
        department_name:'',
        department_shangji:'',
        email:'',        //邮箱
        entry_time:'', //入职时间
        sex_text:'',     //性别
        identity_text:'', //员工角色
        work_year: '',    //工作年限
        F_ull_image:'',    //图片地址
        mobile_hide:0,
      },
      Chan_jieshouren:0, // 选择接收人下标
      Row2:{
        quit_time_text:'', //入职时间
        quit_time:'', //入职时间
        reason:'',    //离职原因
        type:"",      //离职类型:10=主动离职,20=被动离职
        type_text:'',
        quit_status:'',  //办理状态:10=待交接,20=已交接,30=不需交接
        quit_status_text:'',
        is_transfer:null,

        transfer: [
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "order", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
            },
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "customer", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
              
            },
            {
              name:'订单资源',
              people:'转移所有档案',
              type: "file", //离职类型:10=主动离职,20=被动离职
              to_staff_id: 0, //接收人
              to_staff:{
                realname:'',    //接收人
              }
            }
          ] //转移资源，is_transfer=1时显示
      },
      Row3: {        // 新增字段
        name: undefined, //字段名称
        title: undefined, //字段标题
        tip: "", //提示语
        type: "", //字段类型
        content: "", //选项内容
        is_require: 0, //是否必填项:1=是,0=否
        is_show: 0, //是否员工可见:1=是,0=否
        is_edit: 0, //是否可编辑:1=是,0=否
        status: "normal" //启用状态，hidden=未启用,normal=已启用
      },
      Type_List:['日期','日期时间','文件','图片','数字','列表','文本',],
      Type_List_text:'',
      R3_inou:[{name:'选项1',content:''},{name:'选项2',content:''}],
      staffInfo:{}, //存储员工信息
      
      DepartmentList:[],  //通讯录选择部门
      StaffList:[],       //通讯录选择员工
      Parents:[],         //通讯录层级
      Blo_Black:false,    //返回显示  
      Chan_Tonx:null,        //通讯录已选
      Chan_Tonx2:null,        //通讯录已选2
      Bumen_Tonx:null,        //部门已选
      Bumen_Tonx2:null,        //部门已选2

      selection_arr: null,     // 选中离职员工数组
      atat:0,
      atat2:0,

      Si_show:0,
      Id_kddd:0,
      S_Ea:{}
    };
  },
  mounted: function() {
    var that = this
        let token = window.localStorage.getItem("accessToken")
        that.heads.token = token
        axios.get('/admin/company/department/departmentList',{          //部门列表
          params:{
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
              that.BMent = res.data.data.rows
              that.total1 = res.data.data.total
            }
        })

        axios.get('/admin/company/department/index',{   // 部门列表
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent = res.data.data.rows
              console.log(that.DMent)
              that.DMent2 = res.data.data.rows
              that.department_id = res.data.data.rows[0].id
            }
        })
        axios.get('/admin/company/staff/quitList',{   // 模块
            params:{
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.total2 = res.data.data.total
              that.ResginS = res.data.data.rows
              console.log(that.ResginS)
            }
        })

        axios.get('/admin/company/staff/subListFilter',{   // 通讯录选择
            params:{
              id: 0
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents

            }
        })
  
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    handleRowClick(row, column, event){
          this.$refs.multipleTable.toggleRowSelection(row);
    },

    Close(e) {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible7 = false;
      this.dialogVisible8 = false;
      this.dialogVisible10 = false;
      this.dialogVisible11 = false;
      this.show1 = false;
      this.show3 = false;
    },

    Del_Sure() {  // 删除确定
      var that = this
      if (that.del_idw == 'Ment') {
        axios({
            method: 'delete',
            url: '/admin/company/department/del',
            data: {
              id: that.Remov_id
            },
          }).then(function (res) {

              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.dia_DEl = false  
                that.$message.success(res.data.msg);
                axios.get('/admin/company/department/index',{   // 模块
                  }).then(function (res) {
                    if (res.code == 0) {
                      alert('请求失败');  
                    } else{ 
                      that.DMent = res.data.data.rows
                    }
                })
              }
          }) 
      }
    },

    BTN_Bumen3() {
      var that = this
      that.show1 = false
      that.dialogVisible7 = false
      that.Row.department_name = that.Bumen_Tonx2.name
      that.atat2 = that.Bumen_Tonx2.id
      setTimeout(function(){
        that.show1 = true
      },1)
    },

    Add_leibie() {  //创建导航类别
      var that = this
      var ATT = that.Categ
      var srt = {
        content: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.Categ = ATT
    },
    changeSwitch(data,b,index) {   // radio
      var that = this
      // console.log(index)
      // console.log(b)
      var Categ = that.Categ
      console.log(Categ)
      console.log(index)
      console.log('当前值' + Categ[index].status)
      if (Categ[index].status == 'normal') {
        Categ[index].status = 'hidden'
        console.log('进1改变为0')
      } else if (Categ[index].status == 'hidden') {
        console.log('进0改变为1')
        Categ[index].status = 'normal'
        // console.log(Categ[index].status)
        
      }
      that.Categ = Categ
      // console.log(that.Categ)
    },
    handleDelete(index, row) {  // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.Categ
      if (row.type == 'default') {

      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.Categ = ATT
      }

    },
    NabCH_BTN() {
      var that = this
      var ATT = that.Categ
        axios({
          method: 'post',
          url: '/admin/company/content/edit',
          data: {
            field_key: "employee_type", //内容标识
            content: ATT
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.dialogVisible1 = false
                axios.get('/admin/company/content/index?field_key=employee_type',{   // 模块
                  }).then(function (res) {
                    if (res.code == 0) {
                      that.$message.warning(res.data.msg);
                    } else{ 
                      that.MMD = res.data.data.enums
                      that.Categ = res.data.data.enums
                      console.log(res.data.data.enums)
                    }
                })

            }

        }) 
    },
    handleClick2(data,checked, node){  // 勾选变更功能部门内
      var that = this
      that.Bumen_Tonx2 = data
      console.log(data)
    },
    bindCheckBox(value){     //通讯录选择员工
      console.log(value)
      var that = this
      
        if(that.checkList.length > 1){
            that.checkList.splice(0,1)
        }
        var arr = that.checkList.toString()
        console.log(arr)

        var brr = that.StaffList
        console.log(brr)
        for (let i = 0; i < brr.length; i++) {
          if (brr[i].id == arr) {
            that.Chan_Tonx = brr[i]
            that.Chan_Tonx2 = brr[i]
          }
        }
        console.log(that.Chan_Tonx)
        if (value == '') {
          that.Chan_Tonx = null
          that.Chan_Tonx2 = null
        }

    },
    Nice_x(e) {             //通讯录下级
        var that = this
        that.Blo_Black = true
        axios.get('/admin/company/staff/subListFilter',{
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
              console.log(res.data.data)
            }
        })
    },
    Close_2() {             //通讯录取消选择
      var that = this
      console.log('123')
      console.log(that.dialogVisible5)
      that.Chan_Tonx = null
      that.dialogVisible5 = false;

    },
    Close_3() {
      var that = this
      var arr = that.Row2.transfer

      that.Row2.transfer[that.Chan_jieshouren].to_staff.realname = that.Chan_Tonx.realname
      that.Row2.transfer[that.Chan_jieshouren].to_staff_id = that.Chan_Tonx.id
      that.dialogVisible5 = false;
      that.show3 = false
      console.log(that.dialogVisible5)
      setTimeout(function(){
        that.show3 = true
      },1)    
    },
    Close_4() {
      var that = this
      that.Row.department_shangji = that.Chan_Tonx2.realname
      that.atat = that.Chan_Tonx2.id
      that.dialogVisible8 = false;
      that.show1 = false
      console.log(that.Chan_Tonx2)
      setTimeout(function(){
        that.show1 = true
      },1)    
    },
    Bloo_Black(e) {         // 通讯录选择
          var that = this
          axios.get('/admin/company/staff/subListFilter',{   
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
              console.log(res)
            }
        })
    },

 
    Add_kq(e) {
      var that = this
      if (e == 1) {
        that.show1 = true
        that.XX = 'xin'
        that.Fond_tag = null
        that.Row = {}
        that.Row.mobile_hide = 0
        // that.Row.department.name = '222'
      } else if(e == 2){
        that.Row3 = {}
        that.Type_List_text = ''
        that.R3_inou = [{name:'选项1',content:''},{name:'选项2',content:''}]
        that.dialogVisible2 = true;
        that.Ad2_ziduan = 'xin'
      } else if (e == 3) {
        that.show1 = false
        that.show3 = true
      } else if(e == 4) {
        that.editVisible = true
        that.fForm = {}
        that.Bmen_Sj1 = ''
        that.Bmen_Sj2 = ''
        that.Bmmm = false
      }else if(e == 5) {
        that.editVisible = true
        that.fForm = {}
        that.Bmen_Sj1 = ''
        that.Bmen_Sj2 = ''
        that.Bmmm = true
        that.Si_show = 1
      }
    },


    handleSelect(key, keyPath) {  // 上访切换
      var that = this
      console.log(key, keyPath);
      if (key == 4) {
        this.$router.push('/Manage/BaseTable4');
      } else if (key == 1) {
        this.$router.push('/Manage/BaseTable');
      } else if(key == 2) {
        that.Index2 = true
        that.Index1 = false
        that.Index4 = false
        that.Index5 = false

      }
    },

 
   
    handleCurrentChange1(val) {    // 分页 ···············································
      var that = this
      that.tot = val
      console.log(val)
      that.currentPage1 = val
        axios.get('/admin/company/department/departmentList',{          //部门列表
          params:{
              page: val,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
              that.BMent = res.data.data.rows
              that.total1 = res.data.data.total
            }
        })

    },


    //SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS ------------------------------------------部门管理---------------SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSsS-------------------------------------------
    Bmen1(e) {
      var that = this
      if(e == 1) {
        that.dialogVisible10 = true;
      }else if(e == 2) {
        that.dialogVisible11 = true;
      }
    },
    Bmen1_Chan(data) {
      var that = this
      that.Bumen_Xinj1 = data
      console.log(data)
    },
    BTN_Bumen2() {   //选择上级部门确认
      var that = this
      that.dialogVisible10 = false;
      that.editVisible = false
      that.Bmen_Sj1 = that.Bumen_Xinj1.name
      console.log(that.Bumen_Xinj1)
      console.log(that.Bmen_Sj1)
      setTimeout(function(){
        that.dialogVisible10 = false;
        that.dialogVisible11 = false;
        that.editVisible = true
      },1)    
    },
    quxiao() {
      var that = this
        that.editVisible = false
        that.dialogVisible10 = false;
        that.dialogVisible11 = false;
        that.Bumen_Xinj1 = null
        that.Bumen_Xinj2 = null
    },
    bindCheckBox2(value){     //  勾选部门负责人
      console.log(value)
      var that = this
        if(that.checkList2.length > 1){
            that.checkList2.splice(0,1)
        }
        var arr = that.checkList2.toString()
        console.log(arr)

        var brr = that.StaffList
        console.log(brr)
        for (let i = 0; i < brr.length; i++) {
          if (brr[i].id == arr) {
            that.Bumen_Xinj2 = brr[i]
          }
        }
        console.log(that.Bumen_Xinj2)
        if (value == '') {
          that.Bumen_Xinj2 = null
        }
    },
    Close_5() {
      var that = this
      that.Bmen_Sj2 = that.Bumen_Xinj2.realname
      that.dialogVisible10= false;
      that.dialogVisible11 = false;
      that.editVisible = false
      setTimeout(function(){
        that.dialogVisible11 = false
        that.dialogVisible10 = false
        that.editVisible = true
      },1)    
    },
    saveEdit() {    // 新建编辑部门确认
      var that = this
      var arr = 0
      console.log(that.fForm.name)
      if (that.fForm.name == '' || that.fForm.name == undefined) {
        that.$message.warning('请输入部门名称');
      } else if(that.fForm.phone == '' || that.fForm.phone == undefined) {
        that.$message.warning('请输入联系方式');
      } else if(that.Bumen_Xinj2 == null){
        that.$message.warning('请选择部门负责人');
      }else {
        if (that.Si_show == 1) {
          if (that.Bmmm == true) {
          arr = 0
            axios({ 
              method: 'post',
              url: '/admin/company/department/add',    // 
              data: {
                row:{
                  pid: arr, //上级部门ID
                  name: that.fForm.name,
                  leader_id: that.Bumen_Xinj2.id, //主管ID
                  phone: that.fForm.phone
                }
              },
            }).then(function (res) {
                console.log(res)
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                      setTimeout(function(){
                        that.editVisible = false
                        that.dialogVisible11 = false
                        that.dialogVisible10 = false
                      },1)
                  axios.get('/admin/company/department/departmentList',{   // 
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.dialogVisible11 = false
                        that.editVisible = false
                        that.dialogVisible10 = false
                        
                        that.BMent = res.data.data.rows
                      }
                  })
                }

            }) 
          } else {
            if (that.Bumen_Xinj1 == null) {
              that.$message.warning('请选择上级部门');
            } else {
              arr = that.Bumen_Xinj1.id
              axios({ 
              method: 'post',
              url: '/admin/company/department/add',    // 新建部门
              data: {
                row:{
                  pid: arr, //上级部门ID
                  name: that.fForm.name,
                  leader_id: that.Bumen_Xinj2.id, //主管ID
                  phone: that.fForm.phone
                }
              },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                      setTimeout(function(){
                        that.editVisible = false
                        that.dialogVisible11 = false
                        that.dialogVisible10 = false
                      },1)
                  axios.get('/admin/company/department/departmentList',{   // 部门列表
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.dialogVisible11 = false
                        that.editVisible = false
                        that.dialogVisible10 = false
                        
                        that.BMent = res.data.data.rows
                      }
                  })
                }

              }) 
            }
            
          }
        } else if (that.Si_show == 2) {

            axios({ 
              method: 'put',
              url: '/admin/company/department/edit',    // 
              data: {
                id:that.Id_kddd,
                row:{
                  pid: that.S_Ea.pid, //上级部门ID
                  name: that.fForm.name,
                  leader_id: that.Bumen_Xinj2.id, //主管ID
                  phone: that.fForm.phone
                }
              },
            }).then(function (res) {
                console.log(res)
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                      setTimeout(function(){
                        that.editVisible = false
                        that.dialogVisible11 = false
                        that.dialogVisible10 = false
                      },1)
                  axios.get('/admin/company/department/departmentList',{   // 
                    }).then(function (res) {
                      if (res.code == 0) {
                        alert('请求失败');  
                      } else{ 
                        that.dialogVisible11 = false
                        that.editVisible = false
                        that.dialogVisible10 = false
                        
                        that.BMent = res.data.data.rows
                      }
                  })
                }

            }) 
        }
        }

    },
    Bu_del(data,e) {      // 部门删除
      var that = this
      console.log(e.id)
              that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/company/department/del',
            data: {
              id: e.id
            },
          }).then(function (res) {

              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                axios.get('/admin/company/department/departmentList',{ 
                  }).then(function (res) {
                    if (res.code == 0) {
                          that.$message.warning(res.data.msg);
                    } else{ 
                      that.BMent = res.data.data.rows
                      console.log(that.BMent)
                    }
                })
              }
          }) 
        })

    },
    Bu_bianj(c, e) {                   //部门编辑
      var that = this
              that.Si_show = 2
        that.S_Ea = e
      that.Id_kddd = e.id

      if (c == 1) {

        that.editVisible = true
      } else if (c == 2) {
        that.editVisible11 = true
      }
        axios.get('/admin/company/department/info',{   // 模块
          params:{
              id:e.id
          }
          }).then(function (res) {
            if (res.code == 0) {
                that.$message.warning(res.data.msg);
            } else{ 
              that.fForm.name = res.data.data.row.name
              that.fForm.phone = res.data.data.row.phone
              that.Bmen_Sj2 = res.data.data.row.leader.realname
              that.Bumen_Xinj2 = res.data.data.row.leader
              console.log(that.Bmen_Sj2)
              if (res.data.data.row.parent == null) {
                that.Bmmm = true
              }  else {
                that.Bmmm = false
                that.Bmen_Sj1 = res.data.data.row.parent.name
              }
            }
        })
    },
    D_sech() {                //搜索部门
        var that = this
        axios.get('/admin/company/department/departmentList',{          //部门列表
          
          params:{
            keyword:that.input_seach,
              page: 1,
              offset:0,
              limit:10,
            }
          }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
              that.BMent = res.data.data.rows
              that.total1 = res.data.data.total
            }
        })
    },
    Btn_xiugai() {   //该部门名字确认
        var that = this
        axios({
          method: 'put',
          url: '/admin/company/department/edit',
          data: {
            id: that.diasible9_id,
            row:{
              name: that.diasible9
            }
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.dialogVisible9 = false
              axios.get('/admin/company/department/index',{   // 部门列表
                }).then(function (res) {
                  if (res.code == 0) {
                    alert('请求失败');  
                  } else{ 
                    that.DMent = res.data.data.rows
                    that.DMent2 = res.data.data.rows
                    that.department_id = res.data.data.rows[0].id
                  }
              })
            }
        }) 
    },
    select_change(sel) {   
      var that = this
      console.log(sel)
      var searr = []
      for (let i = 0; i < sel.length; i++) {
        const ele = sel[i].id;
        searr.push(ele)
      }
      console.log(searr)
      // let aee = Array.from(searr)
      that.selection_arr = searr
      console.log(that.selection_arr)
    },
    Close_Bumen() {  // 删除已选部门
      var that = this
      that.Bumen_Tonx = null
      that.Bumen_Tonx2  = null
    },

  },

};
</script>

<style scoped> 
.BaseTable2 .ddsddsx {
  background: #fff;
  border: 1px solid #D9D9D9;
  color: #595959;
}

</style>
